var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2 rounded-0 truncate-table",attrs:{"fixed-header":"","headers":_vm.headers,"header-props":{ 'sort-icon': 'arrow_drop_up' },"items":_vm.pendingInvitationsItems,"items-per-page":10,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions: [10, 15, 20, -1],
      firstIcon: 'first_page',
      lastIcon: 'last_page',
      prevIcon: 'keyboard_arrow_left',
      nextIcon: 'keyboard_arrow_right'
    },"search":_vm.search,"no-results-text":"No invitations found. Try expanding your search?","no-data-text":"There are no pending membership invitations."},scopedSlots:_vm._u([{key:"header.email",fn:function(ref){
    var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.role",fn:function(ref){
    var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.created",fn:function(ref){
    var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"item.created",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.created))+" ")]}},{key:"item.role",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","dark":"","color":!_vm.roleColorMap[item.role]
            ? 'accentPink'
            : _vm.roleColorMap[item.role] || 'secondaryLight'}},[_vm._v(" "+_vm._s(_vm.roleMap[item.role] || item.role)+" ")])]}},(_vm.permissionsCheck)?{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"error"},on:{"click":function($event){_vm.selectedUser = item
              _vm.dialogRevokeInvite = true}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Revoke invitation ")])]}}:null],null,true)}),(_vm.selectedUser)?_c('ConfirmDialog',{attrs:{"type":"error","confirm-text":"Revoke","dialog-props":{ 'max-width': '660' },"title":("Are you sure you want to revoke " + (_vm.selectedUser.email) + "'s\n          invitation to your team?"),"disabled":_vm.isRevokingInvitation,"loading":_vm.isRevokingInvitation},on:{"confirm":function($event){return _vm.revokeInvitation(_vm.selectedUser.membershipInvitationId)}},model:{value:(_vm.dialogRevokeInvite),callback:function ($$v) {_vm.dialogRevokeInvite=$$v},expression:"dialogRevokeInvite"}},[_vm._v(" The user will no longer be able to accept this invitation and will need to be reinvited to join the team. ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }