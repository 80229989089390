var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2 rounded-0 truncate-table",attrs:{"fixed-header":"","headers":_vm.headers,"header-props":{ 'sort-icon': 'arrow_drop_up' },"items":_vm.membersItems,"items-per-page":10,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions: [10, 15, 20, -1],
      firstIcon: 'first_page',
      lastIcon: 'last_page',
      prevIcon: 'keyboard_arrow_left',
      nextIcon: 'keyboard_arrow_right'
    },"loading":_vm.loading,"search":_vm.search,"no-results-text":"No members found. Try expanding your search?","no-data-text":"This team does not have any members yet."},scopedSlots:_vm._u([{key:"header.email",fn:function(ref){
    var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.username",fn:function(ref){
    var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.firstName",fn:function(ref){
    var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.lastName",fn:function(ref){
    var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.role",fn:function(ref){
    var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"item.role",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","dark":"","color":!_vm.roleColorMap[item.role]
            ? 'accentPink'
            : _vm.roleColorMap[item.role] || 'secondaryLight'}},[_vm._v(" "+_vm._s(_vm.roleMap[item.role] || item.role)+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(
          _vm.hasPermission('update', 'membership') &&
            _vm.hasPermission('feature', 'basic-rbac')
        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"primary"},on:{"click":function($event){_vm.selectedUser = item
              _vm.roleInput = _vm.selectedUser.role
              _vm.dialogModifyRole = true}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_vm._v(" Modify this user's role ")]):_vm._e(),(_vm.hasPermission('delete', 'membership'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"error"},on:{"click":function($event){_vm.selectedUser = item
              _vm.dialogRemoveUser = true}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Remove user ")]):_vm._e()]}}])}),(_vm.selectedUser)?_c('ConfirmDialog',{attrs:{"dialog-props":{ 'max-width': '600' },"title":("Modify " + (_vm.selectedUser.email) + "'s role"),"disabled":_vm.isSettingRole,"loading":_vm.isSettingRole,"confirm-text":"Save"},on:{"cancel":function($event){_vm.roleInput = 'USER'},"confirm":function($event){return _vm.updateRole(_vm.selectedUser.membershipId, _vm.roleInput)}},model:{value:(_vm.dialogModifyRole),callback:function ($$v) {_vm.dialogModifyRole=$$v},expression:"dialogModifyRole"}},[_c('v-select',{staticClass:"mt-6",attrs:{"data-public":"","outlined":"","dense":"","autofocus":"","label":"Role","color":_vm.roleColorMap[_vm.roleInput],"prepend-icon":"supervised_user_circle","items":_vm.roles,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roleMap[item.name] ? _vm.roleMap[item.name] : item.name)+" ")]}},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roleMap[item.name] ? _vm.roleMap[item.name] : item.name)+" ")]}}],null,false,2051321820),model:{value:(_vm.roleInput),callback:function ($$v) {_vm.roleInput=$$v},expression:"roleInput"}})],1):_vm._e(),(_vm.selectedUser)?_c('ConfirmDialog',{attrs:{"type":"error","title":_vm.deleteSelfWarning
        ? ("Are you sure you want to remove yourself from " + (_vm.tenant.name) + "?")
        : ("Are you sure you want to remove " + (_vm.selectedUser.email) + " from your team?"),"dialog-props":{ 'max-width': '600' },"disabled":_vm.isRemovingUser,"loading":_vm.isRemovingUser},on:{"confirm":function($event){return _vm.removeUser(_vm.selectedUser.membershipId)}},model:{value:(_vm.dialogRemoveUser),callback:function ($$v) {_vm.dialogRemoveUser=$$v},expression:"dialogRemoveUser"}},[(_vm.deleteSelfWarning)?_c('div',{staticClass:"red--text"},[_vm._v("Are you sure you want to remove yourself from "+_vm._s(_vm.tenant.name)+"? You'll no longer be able to access your run data associated with "+_vm._s(_vm.tenant.name)+". ")]):_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.selectedUser.username))]),_vm._v(" will no longer be able to access your team account.")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }